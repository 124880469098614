import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceWidget from "../components/servicewidget.component"
import { Link, graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ServicesPage = () => {
  const query = graphql`
    query {
      allContentfulServiceCategory(sort: { fields: createdAt }) {
        edges {
          node {
            image {
              fluid(maxWidth: 255, maxHeight: 255) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            id
            name
            slug
            displayInHomePage
          }
        }
      }
      contentfulNavigationPages(idDontChange: { eq: "services" }) {
        name
        seo {
          title
          description
          keywords
        }
        slug
        hiddenContent {
          childMarkdownRemark {
            html
          }
        }
        bannerImage {
          fluid(maxWidth: 1600) {
            src
          }
        }
        contentImage {
          fluid {
            src
          }
        }
        content {
          json
        }
      }
    }
  `
  const data = useStaticQuery(query)
  const pageData = data.contentfulNavigationPages

  return (
    <Layout>
      <SEO
        title={pageData.seo.title}
        description={pageData.seo.description}
        keywords={pageData.seo.keywords}
      />
      <section
        className="inner-intro bg-img light-color overlay-before parallax-background"
        style={{
          background: `url(${pageData.bannerImage.fluid.src}) 0 58% no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h1 data-title="Services">
                <span>Services</span>
              </h1>
              <div className="page-breadcrumb">
                <Link to="/">Home</Link>/ <span>Services</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service" className="padding ptb-xs-40">
        <div className="container">
          <div className="row text-center mb-40">
            <div className="col-lg-6 offset-lg-3 sect-title">
              <h2>
                <span>Our</span> Services
              </h2>
              <span className="title_bdr">
                <i className="ion-more" />
              </span>
            </div>
          </div>
          <ServiceWidget data={data} />
          <hr className="divider" />
          <div className="block-title v-line">
            <h2>
              <span>What</span> We Do?
            </h2>
          </div>
          <div className="text-content">
            {documentToReactComponents(pageData.content.json)}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage
